<template>
  <a-modal width="460px" :title="title" @cancel="handleCancel" :visible="visible">
    <a-row>
      <a-col :span="6">截止日</a-col>
      <a-col :span="18">
        <a-date-picker
          format="YYYY-MM-DD HH:mm"
          valueFormat="YYYY-MM-DD HH:mm"
          :show-time="{ format: 'HH:mm', defaultValue: moment('23:59', 'HH:mm') }"
          placeholder="请选择截止日期"
          v-model="deadline"
          style="margin: -5px 0;min-width: 270px;"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="6">人员</a-col>
      <a-col :span="18">
        <a-select
          :showArrow="false"
          showSearch
          placeholder="请输入昵称"
          v-model="ownerId"
          option-filter-prop="label"
          allowClear
          :not-found-content="null"
          @click.stop
          @search="userSearch"
          style="min-width: 270px;"
        >
          <a-select-option :label="user.userName" v-for="user in searchUsers" :key="user.userId" :value="user.userId">
            <img
              style="width:25px;height:25px;border-radius:50%;margin-right:10px;position: relative;top: -2px;"
              :src="user.avatar"
              alt=""
            />
            <span>{{ user.userName }}</span>
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="6">结算月</a-col>
      <a-col :span="18">
        <a-month-picker
          v-model="settlementDate"
          format="YYYY-MM"
          valueFormat="YYYY-MM"
          :show-time="{ format: 'YYYY-MM' }"
          placeholder="请选择结算月份"
          style="width:270px"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="6">结算状态</a-col>
      <a-col :span="18">
        <a-radio-group v-model="settleStatus" :disabled="selectionRows[0]&&selectionRows.some(item=>selectionRows[0].settleStatus!=item.settleStatus)">
          <a-radio value="USER_SETTLED">
            已结算
          </a-radio>
          <a-radio value="NOT_SETTLE">
            未结算
          </a-radio>
        </a-radio-group>
      </a-col>
    </a-row>

    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { postAction } from '@/api/manage'
import moment from 'moment'
export default {
  props: {
    title: {
      type: String,
      default: '批量修改'
    },
    viewType: {
      type: String,
      default: '批量修改'
    },
    allUsers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      deadline: '',
      dataSource: [],
      selectionRows: [],
      selectedRowKeys: [],
      settlementDate: '',
      searchUsers: [],
      ownerId: undefined,
      settleStatus: ''
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 100)
  },
  computed: {
    backCss() {
      let color = this.color
      let backColor = this.$store.state.app.color
      return {
        '--back-color': color,
        '--theme-color': backColor
      }
    }
  },
  methods: {
    moment,
    userSearch(value) {
      if (value) {
        this.searchUsers = [...this.searchUsers, ...this.allUsers.filter(item => item.userName.toLowerCase().indexOf(value.toLowerCase()) > -1)]
        this.searchUsers = [...new Set(this.searchUsers)]
      } else {
        this.searchUsers = []
      }
    },
    async open(selectionRows) {
      this.visible = true
      this.deadline = ''
      this.selectionRows = selectionRows
      if(this.selectionRows.every(item=>item.settleStatus=='NOT_SETTLE')){
        this.settleStatus='NOT_SETTLE'
      }
    },
    async handleOk() {
      let saveArr = []
      this.selectionRows.forEach(item => {
        saveArr.push({
          taskId: item.taskId,
          ownerId: this.ownerId || item.ownerId,
          settleStatus: this.settleStatus,
          id: item.taskId,
          chapterId: item.chapterId,
          stageId: item.stageId,
          productionId:item.productionId,
          settlementDate: this.settlementDate ? this.settlementDate + '-01' : null,
          deadline:
            this.deadline.length <= 17 && this.deadline.length > 10
              ? this.deadline + ':00'
              : this.deadline.length <= 10
              ? this.deadline + ' 23:59:59'
              : this.deadline
        })
      })
      saveArr.forEach(item => {
        if (!this.deadline) {
          delete item.deadline
        }
        if (!this.settlementDate) {
          delete item.settlementDate
        }
      })
      const res = await postAction('/tasks/updateTasks', saveArr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        if (this.deadline || this.ownerId) {
          this.$emit('getTaskTableData')
        } else {
          this.$emit('changeTaskList', { settlementDate: this.settlementDate,settleStatus:this.settleStatus,setArr:res.data })
        }
        this.initData()
        this.visible = false
      } else {
        this.$message.error(res.msg)
      }
    },
    initData() {
      this.settlementDate = ''
      this.deadline = ''
      this.ownerId = undefined
    },
    handleCancel() {
      this.visible = false
      this.initData()
      /* this.$emit('getTaskTableData') */
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .up-msg-status .ant-btn {
  z-index: 0 !important;
}
.text-status .anticon-check-circle {
  color: var(--theme-color);
  position: relative;
  top: 1px;
}
.img-num-title {
  display: inline-block;
  width: 42px;
}
.ant-row {
  min-height: 60px;
  .ant-col {
    line-height: 60px;
  }
}
</style>
